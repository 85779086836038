
  import { Component } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { isValidNumber } from '@/utils/general'
  import { GForm } from '@/components/forms/GForm'
  import { plainToInstance } from 'class-transformer'
  import { PositionType } from '@/entities/hr'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  components: { BaseForm, GAlert },
  methods: { },
})
  export default class SysopPositionForm extends GForm {
  position = plainToInstance(PositionType, {})
  title = ''
  displayError = false
  alert = {
    open: false,
    title: '',
    subtitle: '',
  }

  metadataCollection = null

  formData = {
    name: '',
    description: '',
    active: true,
  }

  errorMessage = {
    name: '',
  }

  displayActiveAlert: boolean = false

  async mounted () {
    this.loadingForm = true
    const { uid } = this
    // const { metadata } = this.getForm('PositionType', 'sysop_position')

    // this.metadataCollection = metadata

    if (isValidNumber(uid)) {
      this.title = 'EDITAR CARGO'
      await this.getPositionInfo(uid)
    } else {
      this.title = 'CREAR CARGO'
    }
    // if (!this.isBreadCrumbPresent(title)) {
    //   this.setFormCrumbs(metadata, title, Boolean(this.position?.id))
    // }
    this.loadingForm = false
  }

  async getPositionInfo (id) {
    this.position = await this.fetchData({
      query: { name: 'fetch', model: 'PositionType', params: { id } },
      force: true,
    })

    this.formData.name = this.position.formatedName
    this.formData.description = this.position.description
    this.formData.active = this.position.active
  }

  async send () {
    this.loadingForm = true

    const success = await this.pushPosition()

    this.loadingForm = false

    if (success) {
      await this.closeForm()
    }
  }

  async validateName (newName: string) {
    const sameNamePositions = await this.fetchData({
      query: { name: 'find', model: 'PositionType' },
      filter: { name: { _eq: newName.trim().toUpperCase() } },
      force: true,
    })

    if (sameNamePositions.length && sameNamePositions.some(position => position.id !== this.uid)) {
      this.errorMessage.name = 'Ya existe un cargo con este nombre.'
    } else {
      this.errorMessage.name = ''
      return true
    }
  }

  async validateActive () {
    const { formData, position } = this

    if (formData.active || !position.id) {
      this.displayActiveAlert = false
      return
    }

    const employeePositions = await this.fetchData({
      query: { name: 'find', model: 'EmployeePosition' },
      filter: { id_position_type: { _eq: position.id } },
      force: true,
    })

    if (employeePositions.length) {
      this.displayActiveAlert = true
    } else {
      this.displayActiveAlert = false
    }
  }

  async closeForm () {
    await this.close()
  }

  async pushPosition () {
    const { formData, position, correctedName } = this

    if (!(await this.validateName(this.formData.name))) return

    let positionId = position.id
    if (!positionId) {
      const createdPosition = await this.pushData({
        model: 'PositionType',
        fields: {
          name: correctedName,
          description: formData.description,
          active: formData.active,
        },
      })
      positionId = createdPosition.id
    } else {
      await this.pushData({
        model: 'PositionType',
        fields: {
          id: positionId,
          name: correctedName,
          description: formData.description,
          active: formData.active,
        },
      })
    }

    return true
  }

  get correctedName () {
    return this.formData.name.trim().toUpperCase()
  }

  get isEditing () {
    const { uid } = this

    return isValidNumber(uid)
  }

  get change () {
    const { formData } = this

    return JSON.stringify(formData)
  }
  }
